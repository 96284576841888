.register-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  position: relative;
}

.login-time {
  font-size: 16px;
  position: fixed;
  top: 30px;
  left: 30px;
}



.bottom {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 50%;
  height: 50px;
  font-weight: bold;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0159b2;
}

.first {
  margin-right: 50px !important;
  position: relative;

}

.register-box {
  width: 400px;
  padding: 40px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.register-logo {
  width: 100px;
  margin-bottom: 20px;
}