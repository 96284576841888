/* forgotPassword.css */

.forgot-password-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.forgot-password-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 24px;
  color: #1890ff;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-btn-primary {
  width: 100%;
  margin-top: 16px;
}

.ant-input {
  height: 40px;
  border-radius: 4px;
}

.ant-input-password {
  height: 40px;
}

@media (max-width: 600px) {
  .forgot-password-container {
    width: 90%;
    padding: 10px;
  }

  .forgot-password-container h2 {
    font-size: 20px;
  }
}
