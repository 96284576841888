

.login-time {
  font-size: 16px;
  position: fixed;
  top: 30px;
  left: 30px;
}

.bottom {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 50%;
  height: 50px;
  font-weight: bold;
  transform: translateX(-50%);
  color: #ffffff;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #0159b2;
}

.first {
  margin-right: 50rpx;
}

.login-box {
  width: 400px;
  padding: 40px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  text-align: center;
}

.login-logo {
  width: 100px;
  margin-bottom: 20px;
}

.login-form {
  text-align: left;
}

.login-options {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.forgot-password-link {
  color: #1890ff;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password-link:hover {
  text-decoration: underline;
}

.register-option {
  margin-top: 20px;
  font-size: 14px;
}

.register-option a {
  color: #1890ff;
  text-decoration: none;
}

.register-option a:hover {
  text-decoration: underline;
}

button[type="submit"] {
  width: 100%;
}