

.funding-application-container {
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.funding-application-container h2 {
  text-align: center;
  margin-bottom: 20px;
  font-size: 28px;
  color: #1890ff;
}

.funding-application-container h3 {
  margin-top: 30px;
  margin-bottom: 15px;
  font-size: 22px;
  color: #333;
}

.ant-form-item {
  margin-bottom: 16px;
}

.ant-input,
.ant-input-number,
.ant-textarea {
  height: 40px;
  border-radius: 4px;
}

.ant-input-number input {
  height: 40px;
}

.ant-btn-primary{
  width: 100%;
  margin-top: 16px;
  height: 40px;
  font-size: 16px;
}

@media (max-width: 600px) {
  .funding-application-container {
    width: 90%;
    padding: 10px;
  }

  .funding-application-container h2 {
    font-size: 24px;
  }

  .funding-application-container h3 {
    font-size: 20px;
  }
}
