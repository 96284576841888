.homepage-container {
  padding: 20px;
}

.homepage-card {
  text-align: center;
  min-height: 260px;
  position: relative;
}



.homepage-card p {
  margin-bottom: 16px;
}